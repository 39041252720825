/**
 * A class that can return the Choice model
 */
export class Choice {
  /** @param {Object} choice FastJson object. */
  constructor(choice = {}) {
    this.id = choice.id || null;
    this.title = choice.attributes ? choice.attributes.title : null;
    this.correct = choice.attributes ? choice.attributes.model_answer : false;
    this.destroy =  false;
  }
}
