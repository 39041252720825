/**
 * A class that can return the Reply model
 */
import { User } from 'models/user';
import moment from 'moment';

export class Reply {
  /** @param {Object} reply represents the API file object */
  constructor(reply = {}) {
    this.id = reply.id || null;
    if (reply.attributes) {
      if (reply.attributes.created_at) {
        this.createdAt = moment(reply.attributes.created_at).locale(localStorage.locale).calendar();
      } else {
        this.createdAt = null;
      }
      this.text = reply.attributes.text || null;
      this.sender = reply.attributes.sender ? new User(reply.attributes.sender) : {};
      this.senderType = reply.attributes.sender_type ? reply.attributes.sender_type : {};
    }
  }

  sentByUser() {
    return this.senderType === 'User';
  }
}
