import { Article } from 'models/article';
import { List, ListParams } from 'models/list';

const state = {
  articles: new List(),
  article: new Article()
};

const getters = {
  articles: (state) => state.articles,
  article: (state) => state.article
};

const actions = {
  async getArticles({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/articles', {params: params});
    if (response.status === 200) {
      commit('setArticles', response.data);
    }
  },

  async getArticle({commit}, options) {
    const response = await this.$app.$http.get(`/articles/${options.id}`);
    if (response.status === 200) {
      commit('setArticle', response.data);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async createArticle({}, data) {
    const response = await this.$app.$http.post('/articles', data);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.article.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateArticle({}, data) {
    const response = await this.$app.$http.put(`/articles/${data.recordId}`, data.form);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.article.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyArticle({}, id) {
    const response = await this.$app.$http.delete(`/articles/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.article.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.article.entity', 1)}));
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async publishArticle({}, data) {
    const response = await this.$app.$http.put(`/articles/${data.id}/publish`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.published'));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async unpublishArticle({}, data) {
    const response = await this.$app.$http.put(`/articles/${data.id}/unpublish`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.unpublished'));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  }
};

const mutations = {
  setArticles: (state, data) => {
    state.articles = new List({ 
      items: data.items.map((article) => new Article(article)),
      totalCount: data.total_count
    });
  },
  setArticle: (state, data) => {
    state.article = new Article(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
