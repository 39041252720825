export default {
  constants: {
    signIn: 'تسجيل دخول',
    signInMessage: 'أدخل اسم المستخدم وكلمة المرور',
    next: 'التالي',
    prev: 'السابق',
    searchEntity: 'البحث عن {entity}',
    question: 'سؤال',
    article: 'مقال',
    frequentQuestion: 'سؤال متداول',
    filter: 'بحث',
    edit: 'تعديل',
    new: 'جديد',
    totalUnread: 'الرسائل التي لم يرد عليها:',
    send: 'إرسال',
    search: 'بحث',
    typeMessage: 'اكتب رسالة...',
    editProfile: 'تعديل البروفايل',
    logout: 'تسجيل الخروج',
    viewsCount: 'عدد المشاهدات',
    reviews: 'المراجعات'
  },
  breadcrumbs: {
    new: '{entity} جديد',
    edit: 'تعديل {entity}'
  },
  placeholders: {
    quiz: {
      questionTitle: 'عنوان السؤال',
      questionComment: 'التعليق',
      option: 'إختيار',
      instructions: 'إرشادات الاختبار'
    },
    multiple_choices: 'و {count} مستشارين اخرين'
  }
};
