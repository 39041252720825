export default [
  {
    path: '/',
    name: 'MainPanel',
    redirect: '/articles',
    component: () => import('views/main/index'),
    children: [{
      path: '/home',
      name: 'Home',
      component: () => import('views/main/home')
    },
    {
      path: '/invite',
      name:'Invite',
      component: () => import('pages/admin/invitation')
    },
    {
      path: '/system-feedbacks',
      name: 'SystemFeedbackList',
      component: () => import('pages/feedback/list')
    },
    {
      path: '/articles',
      name:'ArticleList',
      component: () => import('pages/article/article-list')
    },
    {
      path: '/articles/new',
      name:'ArticleNew',
      component: () => import('pages/article/article-form')
    },
    {
      path: '/articles/:id/edit',
      name:'ArticleEdit',
      component: () => import('pages/article/article-form')
    },
    {
      path: '/audios',
      name:'AudioList',
      component: () => import('pages/audio/audio-list')
    },
    {
      path: '/audios/new',
      name:'AudioNew',
      component: () => import('pages/audio/audio-form')
    },
    {
      path: '/audios/:id/edit',
      name:'AudioEdit',
      component: () => import('pages/audio/audio-form')
    },  
    {
      path: '/frequent-questions',
      name:'FrequentQuestionList',
      component: () => import('pages/frequent_question/frequent-question-list')
    },
    {
      path: '/frequent-questions/new',
      name:'FrequentQuestionNew',
      component: () => import('pages/frequent_question/frequent-question-form')
    },
    {
      path: '/frequent-questions/:id/edit',
      name:'FrequentQuestionEdit',
      component: () => import('pages/frequent_question/frequent-question-form')
    },
    {
      path: '/service-providers',
      name:'ServiceProviderList',
      component: () => import('pages/service_provider/service-provider-list')
    },
    {
      path: '/service-providers/new',
      name:'ServiceProviderNew',
      component: () => import('pages/service_provider/service-provider-form')
    },
    {
      path: '/service-providers/:id/edit',
      name:'ServiceProviderEdit',
      component: () => import('pages/service_provider/service-provider-form')
      
    },
    {
      path: '/service-providers/:id/reviews',
      name:'serviceProviderFeedbacks',
      component: () => import('pages/service_provider/feedback-list')
    },
    {
      path: '/messages',
      name:'MessageList',
      component: () => import('pages/messages/message-list')
    },
    { 
      path: '/videos',
      name:'VideoList',
      component: () => import('pages/video/video-list')
    },
    {
      path: '/videos/new',
      name:'VideoNew',
      component: () => import('pages/video/video-form')
    },
    {
      path: '/videos/:id/edit',
      name:'VideoEdit',
      component: () => import('pages/video/video-form')
    },
    {
      path: '/quizzes',
      name:'QuizList',
      component: () => import('pages/quiz/quiz-list')
    },
    {
      path: '/quizzes/new',
      name:'QuizNew',
      component: () => import('pages/quiz/quiz-form')
    },
    {
      path: '/quizzes/:id/edit',
      name:'QuizEdit',
      component: () => import('pages/quiz/quiz-form')
    },
    {  
      path: '/admins',
      name: 'AdminList',
      component: () => import('pages/admin/list')
    },
    {
      path: '/admins/:id/edit',
      name: 'AdminEdit',
      component: () => import('pages/admin/edit')
    },
    {
      path: '/users',
      name: 'UserList',
      component: () => import('pages/user/list')
    }, 
    {
      path: '/users/:id/edit',
      name: 'UserEdit',
      component: () => import('pages/user/edit')
    }]
  }
];
