/**
 * A class that can return the User model
 */
export class SystemFeedback {
  /** @param {Object} systemFeedback FastJson object. */
  constructor(systemFeedback = {}) {
    this.id = systemFeedback.id || null;
    if(systemFeedback.attributes) {
      const attributes = systemFeedback.attributes;
      this.rating = attributes.rating || null;
      this.positiveFeedback = attributes.positive_feedback || null;
      this.negativeFeedback = attributes.negative_feedback || null;
      this.content = attributes.content || null;
      this.userId = attributes.user_id || null;
      this.seen = attributes.seen || null;
    }
  }
}
