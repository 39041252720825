/**
 * A class that can return the ServiceProvider model
 */
export class ServiceProvider {
  /** @param {Object} serviceProvider represents the API file object */
  constructor(serviceProvider = {}) {
    this.id = serviceProvider.id || null;
    if (serviceProvider.attributes) {
      this.header = serviceProvider.attributes.header || null;
      this.description = serviceProvider.attributes.description || null;
      this.truncated_description = serviceProvider.attributes.truncated_description || null;
      this.address = serviceProvider.attributes.address || null;
      this.consultants = serviceProvider.attributes.consultants.map((consultant) => {
        return { name: consultant.attributes.name, id: consultant.id  };
      });
      this.pendingReviewsCount = serviceProvider.attributes.pending_reviews_count || null;
      this.tagList = serviceProvider.attributes.tags || null;
      this.email = serviceProvider.attributes.email || null;
      this.authorId = serviceProvider.attributes.author_id || null;
      this.phoneNo = serviceProvider.attributes.phone_no || null;
      this.latitude = serviceProvider.attributes.latitude || null;
      this.longitude = serviceProvider.attributes.longitude || null;
      this.image = serviceProvider.attributes.image || null;
      this.locationImage = serviceProvider.attributes.location_image || null;
      this.overallRating = serviceProvider.attributes.overall_rating || null;
    }
  }
}
