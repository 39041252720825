import axios from 'axios';
import store from '../store';
import _ from 'lodash';

const API_URL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

axiosInstance.defaults.params = {};
axiosInstance.defaults.params['locale'] = localStorage.locale;

axiosInstance.interceptors.request.use((config) => {
  config.headers['access-token'] = store.getters.accessToken;
  config.headers['uid'] = store.getters.uid;
  config.headers['client'] = store.getters.client;
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  if (!_.isNil(response)) {
    return response;
  }
}, (error) => {
  // if (_.isNil(error) || _.isNil(error.response) || error.response.status === 500) {
  //   window.location = '/error-500';
  // }
  switch (error.response.status) {
  case 401:
    if (window.location.pathname !== '/login') {
      store.dispatch('resetSession');
      window.location = '/login';
    } else {
      return {
        errors: error.response.data,
        status: error.response.status
      };
    }
    break;
  case 403:
    window.location = '/';
    break;
  default:
    return {
      errors: error.response.data,
      status: error.response.status
    };
  }
});

export default axiosInstance;
