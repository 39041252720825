import Vue from 'vue';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import router from './router';
import Breadcrumbs from './components/breadcrumbs';
import Vue2Filters from 'vue2-filters';
import VueSweetalert2 from 'vue-sweetalert2';
import VueFormWizard from 'vue-form-wizard';
import VueTour from 'vue-tour';
import Notifications from 'vue-notification';
import { Vue2Dragula } from 'vue2-dragula';
import Toasted from 'vue-toasted';
import SmartTable from 'vuejs-smart-table';
import * as VueGoogleMaps from 'vue2-google-maps';
import { VueMasonryPlugin } from 'vue-masonry';
import VueFeather from 'vue-feather';
import { Icon } from 'leaflet';
import store from './store';
import axios from './plugins/axios';
import SharedComponents from './components/shared';
import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import i18n from './plugins/i18n';
import './helpers/mixins';
import * as Sentry from '@sentry/vue';

delete Icon.Default.prototype._getIconUrl;

// Import Theme scss
import 'assets/scss/app.scss';

Icon.Default.mergeOptions({
  iconRetinaUrl: require('assets/images/vue2leaflet/marker-icon.png'),
  iconUrl: require('assets/images/vue2leaflet/marker-icon.png')
});

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: 'https://96b056c9748f411ebb19a9cc4204fb41@o289894.ingest.sentry.io/5812406',
    logErrors: true
  });
}
Vue.use(VueFeather);
Vue.use(Toasted,{
  iconPack: 'fontawesome',
  theme: 'bubble',
  position: 'bottom-right',
  duration: 2000
});
Vue.use(VueGoogleMaps, {
  load: {
    key: 'API_KEY',
    libraries: 'places'
  }
});
Vue.use(VueLodash, {lodash: lodash});
Vue.use(Vue2Dragula);
Vue.use(Notifications);
Vue.use(Vue2Filters);
Vue.use(VueSweetalert2);
Vue.use(VueFormWizard);
Vue.use(VueTour);
Vue.use(BootstrapVue);
Vue.use(SmartTable);
Vue.use(VueMasonryPlugin);
Vue.use(require('vue-chartist'));
Vue.use(require('vue-moment'));
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.use(SharedComponents);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  axios,
  i18n,
  render: (h) => h(App)
});

store.$app = app;

app.$mount('#app');

