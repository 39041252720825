export default {
  submit: 'Submit',
  login: 'Login',
  setPassword: 'Set Password',
  resetPassword: 'Reset Password',
  register: 'Register',
  new: 'New',
  create: 'Create {entity}',
  find: 'Find {entity}',
  search: 'search',
  noOptions: 'No options',
  save: 'Save',
  confirm: 'Confirm',
  cancel: 'Cancel',
  update: 'Update',
  invite: 'Invite',
  list: 'List',
  filter: 'Filter',
  show: 'Show',
  read: 'Mark as read',
  findAudios: 'Find audios',
  createdByMe: 'Created By Me',
  deleteConfirmation: 'Are you sure you want to delete this record?',
  delete: 'Delete',
  addQuestion: 'Add new question',
  addChoice: 'Add new choice',
  accept: 'Accept',
  reject: 'Reject'
};
