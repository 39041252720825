export default {
  error: {
    // Server responses
    internalServerError: 'An error has occured!',
    forbidden: 'You don\'t have permission to access this resource',
    authentication: 'E-mail or Password are not correct!',
    created: 'An error has been occured while creating!',
    updated: 'An error has been occured while updating!',
    deleted: 'An error has been occured while deleting!',
    notFound: '{entity} is not found!',

    // Input Fields errors:
    required: '{entity} is required',
    emailFormat: 'Invalid e-mail format',
    phoneFormat: 'Invalid phone format',
    noContent: 'There is no content',
    urlFormat: 'Invalid url format',
    maxCharacter: '{entity} must be less than {length} characters',
    minCharacter: '{entity} must be greater than {length} characters',
    lessThan: '{entity} must be less than {value}',
    lessThanOrEqual: '{entity} must be less than or equal {value}',
    greaterThan: '{entity} must be greater than {value}',
    greaterThanOrEqual: '{entity} must be greater than or equal {value}',
    unmatchPassword: 'Passwords you entered don\'t match',
    fileSize: '{entity} size should be less than {size} MB!',

    //Page Errors
    backToHome: 'BACK TO HOME PAGE',
    pageNotAvailable: 'The page you are attempting to reach is not available.',


    user: {
      invitation: 'Failed to send invitation to!',
      alreadyExits: 'Email already exists!'
    },

    quiz: {
      questionTitle: 'The Question has no Title',
      correctChoices: 'The Question has no correct answers',
      choiceTitle: 'Option #{index} has no Title',
      questionsCount: 'The Quiz has no Questions',
      choicesCount: 'The Question has no Options'
    }
  },
  success: {
    invitation: 'Invitation sent successfully!',
    authentication: 'Welcome back!',
    created: '{entity} was created succesfully!',
    updated: '{entity} was updated succesfully!',
    deleted: '{entity} was deleted succesfully!',
    resetPassword: 'Please check you email to reset your password.'
  }
};
