export default [
  {
    path:'/error-400',
    name:'Error400',
    component:() => import('views/errors/error_400'),
    meta: {
      title: 'Error400'
    }
  },
  {
    path:'/error-401',
    name:'Error401',
    component:() => import('views/errors/error_401'),
    meta: {
      title: 'Error401'
    }
  },
  {
    path:'/error-403',
    name:'Error403',
    component:() => import('views/errors/error_403'),
    meta: {
      title: 'Error403'
    }
  },
  {
    path:'/error-404',
    name:'Error404',
    component:() => import('views/errors/error_404'),
    meta: {
      title: 'Error404'
    }
  },
  {
    path:'/error-500',
    name:'Error500',
    component:() => import('views/errors/error_500'),
    meta: {
      title: 'Error500'
    }
  },
  {
    path:'/error-503',
    name:'Error503',
    component:() => import('views/errors/error_503'),
    meta: {
      title: 'Error503'
    }
  }
];
