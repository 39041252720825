/**
 * A class that can return the Article model
 */
export class Article {
  /** @param {Object} article represents the API file object */
  constructor(article = {}) {
    this.id = article.id || null;
    if (article.attributes) {
      this.title = article.attributes.title || null;
      this.body = article.attributes.body || null;
      this.truncated_body = article.attributes.truncated_body || null;
      this.tags = article.attributes.tags || null;
      this.createdAt = article.attributes.created_at || null;
      this.authorId = article.attributes.author_id || null;
      this.searchableItemAttributes = article.attributes.tags || null;
      this.viewsCount = article.attributes.views_count || null;
      this.image = article.attributes.image || null;
      this.published = article.attributes.published || null;
    }
  }
}
