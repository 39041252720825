import { Message } from 'models/message';
import { Reply } from 'models/reply';
import { List, ListParams } from 'models/list';

const state = {
  totalUnread: 0,
  messages: new List(),
  replies: new List()
};

const getters = {
  totalUnread: (state) => state.totalUnread,
  messages: (state) => state.messages,
  replies: (state) => state.replies
};

const actions = {
  async getMessages({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/messages', {params: params});
    if (response.status === 200) {
      commit('setMessages', response.data);
    }
  },

  async getReplies({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get(`/messages/${options.id}/replies`, {params: params});
    if (response.status === 200) {
      commit('setReplies', response.data);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async sendReply({}, options) {
    const response = await this.$app.$http.post(`/messages/${options.id}/replies`, {text: options.text});
    if (response.status !== 200) {
      this.$app.displayErrors(response.errors);
    }
  },

  async getTotalUnread({commit}) {
    const response = await this.$app.$http.get('/messages/total_unread');
    if (response.status === 200) {
      commit('setTotalUnread', response.data);
    }
  }
};

const mutations = {
  setTotalUnread: (state, data) => {
    state.totalUnread = data.total_unread;
  },

  setMessages: (state, data) => {
    state.messages = new List({
      items: data.items.map((message) => new Message(message)), totalCount: data.total_count
    });  
  },

  setReplies: (state, data) => {
    state.replies = new List({
      items: data.items.map((reply) => new Reply(reply)), totalCount: data.total_count
    }); 
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
