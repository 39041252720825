import { List, ListParams } from 'models/list';
import { Feedback } from 'models/feedback';

const state = {
  serviceProviderFeedbacks: new List()
};

const getters = {
  serviceProviderFeedbacks: (state) => state.serviceProviderFeedbacks
};

const actions = {
  async getServiceProviderFeedbacks({commit}, requestParams){
    const params = new ListParams(requestParams.options);
    const response = await this.$app.$http.get(
      `/feedbacks?consulting_service_id=${ requestParams.serviceProviderId }`,
      { params: params }
    );
    if (response.status === 200) {
      commit('setServiceProviderFeedbacks', response.data);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async feedbackUpdateStatus({}, resp) {
    const response = await this.$app.$http.put(
      `/feedbacks/${ resp.id }`,
      { status: resp.status }
    );
    return response.status;
  }
};

const mutations = {
  setServiceProviderFeedbacks: (state, data) => {
    state.serviceProviderFeedbacks = new List({
      items: data.items.map((feedback) => new Feedback(feedback)), totalCount: data.total_count
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
