import { languages } from 'constants';
import { Admin } from 'models/admin';

const state = {
  currentLanguage: localStorage && localStorage.locale === 'ar' ? languages.ar : languages.en,
  currentUser: localStorage && localStorage.currentUserData ? JSON.parse(localStorage.currentUserData) : new Admin(),
  accessToken: localStorage && localStorage.accessToken ? localStorage.accessToken : null ,
  client: localStorage && localStorage.client ? localStorage.client : null ,
  uid: localStorage && localStorage.uid ? localStorage.uid : null
};

const getters = {
  languages: () => languages,
  currentLanguage: (state) => state.currentLanguage,
  currentUser: (state) => state.currentUser,
  accessToken: (state) => state.accessToken,
  client: (state) => state.client,
  uid: (state) => state.uid
};

const actions = {
  async switchLanguage({commit}, language) {
    commit('setCurrentLanguage', language);
    this.$app.$i18n.locale = language.i18nLocale;
  },
  async login({commit}, data) {
    const response = await this.$app.$http.post('/auth/sign_in', data);
    if (response.status === 200) {
      commit('setCurrentUserData', new Admin(response.data.data));
      commit('setAuthTokens', response.headers);
      this.$app.$router.push('/');
    } else {
      this.$app.$toasted.clear();
      this.$app.$toasted.error(this.$app.$t('messages.error.authentication'));
      localStorage.clear();
    }
  },
  async logout({commit}) {
    const response = await this.$app.$http.delete('/auth/sign_out');
    if (response.status === 200) {
      commit('clearUserSession');
      this.$app.$router.push('/login');
    }
  }
};

const mutations = {
  setCurrentLanguage: (state, language) => {
    localStorage.setItem('locale', language.locale);
    state.currentLanguage = language;
  },
  setCurrentUserData: (state, userData) => {
    localStorage.setItem('uuid', userData.id);
    localStorage.setItem('currentUserData', JSON.stringify(userData));

    state.currentUser = userData;
  },
  setAuthTokens: (state, tokens) => {
    localStorage.setItem('accessToken', tokens['access-token']);
    localStorage.setItem('client', tokens['client']);
    localStorage.setItem('uid', tokens['uid']);

    state.accessToken = tokens['access-token'];
    state.client = tokens['client'];
    state.uid = tokens['uid'];
  },
  clearUserSession: (state) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
    localStorage.removeItem('currentUserData');

    state.currentUser = new Admin();
    state.accessToken = '';
    state.client = '';
    state.uid = '';

  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
