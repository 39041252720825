/**
* A class that can return the User model
*/
export class Feedback {
  /** @param {Object} feedback FastJson object. */
  constructor(feedback = {}) {
    this.id = feedback.id || null;
    if(feedback.attributes) {
      const attributes = feedback.attributes;
      this.status = attributes.status || null;
      this.title = attributes.title || null;
      this.details = attributes.detail || null;
      this.rating = attributes.rating || null;
    }  
  }
}
