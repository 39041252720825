export default {
  user: {
    entity: 'User | Users',
    attributes: {
      email: 'Email',
      password: 'Password',
      role: 'Role',
      name: 'Name',
      testUser: 'Test User'
    }
  },
  systemFeedback: {
    entity: 'System Feedback | System Feedbacks',
    attributes: {
      content: 'Content',
      rating: 'Rating',
      positive_feedback: 'Positive Feedback',
      negative_feedback: 'Negative Feedback'
    }
  },
  article: {
    entity: 'Article | Articles',
    attributes: {
      title: 'Title',
      body: 'Body',
      tags: 'Tags',
      image: 'Image'
    }
  },
  audio: {
    entity: 'Audio | Audios',
    attributes: {
      title: 'Title',
      url: 'URL',
      description: 'Description',
      tags: 'Tags',
      image: 'Image'
    }
  },
  frequentQuestion: {
    entity: 'FAQ | FAQs',
    attributes: {
      question: 'Question',
      answer: 'Answer',
      category: 'Category'
    }
  },
  serviceProvider: {
    entity: 'Service Provider | Service Providers',
    attributes: {
      header: 'Title',
      address: 'Address',
      location: 'Location',
      description: 'Description',
      email: 'Email',
      phoneNumber: 'Phone no.',
      category: 'Category',
      consultant: 'Consultant',
      latitude: 'Latitude',
      longitude: 'Longitude',
      image: 'Image',
      locationImage: 'Lcoation Image'
    }
  },
  feedback: {
    entity: 'Feedback | Feedbacks',
    attributes: {
      title: 'Title',
      details: 'Details',
      status: 'Status'
    }
  },
  message: {
    entity: 'message | messages'
  },
  video: {
    entity: 'Video | Videos',
    attributes: {
      title: 'Title',
      url: 'Url',
      description: 'Description',
      tags: 'Tags',
      image: 'Image'
    }
  },
  admin: {
    entity: 'Admin | Admins',
    attributes: {
      name: 'Name',
      role: 'Role',
      email: 'Email',
      image: 'Image',
      nickname: 'Nickname',
      password: 'Password',
      password_confirmation: 'Password Confirmation'
    }
  },
  content: {
    entity: 'Content | Content',
    attributes: {
      title: 'Title',
      tags: 'Tags'
    }
  },
  quiz: {
    entity: 'Quiz | Quizzes',
    attributes: {
      title: 'Title',
      instructions: 'Instructions',
      questions_count: 'Number of questions'
    }
  },
  question: {
    entity: 'Question | Questions',
    attributes: {
      title: 'Title',
      choices: 'Choices'
    }
  }
};
