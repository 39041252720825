/**
 * A class that can return the Quiz model
 */
import { List } from './list';
import { Question } from './question';

export class Quiz {
  /** @param {Object} quiz FastJson object. */
  constructor(quiz = {}) {
    this.id = quiz? quiz.id : null;
    this.title = quiz.attributes ? quiz.attributes.title : null;
    this.instructions = quiz.attributes ? quiz.attributes.instructions : null;
    this.truncated_instructions = quiz.attributes ? quiz.attributes.truncated_instructions : null;
    this.image = quiz.attributes && quiz.attributes.image ? quiz.attributes.image : null;

    this.questions = quiz.attributes && quiz.attributes.questions ? new List({ 
      items: quiz.attributes.questions.map((question) => new Question(question))
    }) : new List({items: [new Question()], count: 1});
  }
}
