import { ServiceProvider } from 'models/service_provider';
import { List, ListParams } from 'models/list';

const state = {
  serviceProviders: new List(),
  serviceProvider: new ServiceProvider(),
  consultantsList: new List()
};

const getters = {
  serviceProviders: (state) => state.serviceProviders,
  serviceProvider: (state) => state.serviceProvider,
  consultantsList: (state) => state.consultantsList
};

const actions = {
  async getServiceProviders({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/consulting_services', {params: params});
    if (response.status === 200) {
      commit('setServiceProviders', response.data);
    }
  },

  async getServiceProvider({commit}, options) {
    const response = await this.$app.$http.get(`/consulting_services/${options.id}`);
    if (response.status === 200) {
      commit('setServiceProvider', response.data);
    }
  },
  async createServiceProvider({commit}, data) {
    const response = await this.$app.$http.post('/consulting_services', data);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.serviceProvider.entity', 1)
      }));
      commit('setServiceProvider', response.data);
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateServiceProvider({}, data) {
    const response = await this.$app.$http.put(`/consulting_services/${data.recordId}`, data.form);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.serviceProvider.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyServiceProvider({}, id) {
    const response = await this.$app.$http.delete(`/consulting_services/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.serviceProvider.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.serviceProvider.entity', 1)}));
    }
  },

  async searchConsultants({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/users', {params: params});
    if (response.status === 200) {
      commit('setConsultantsList', response.data);
    }
  }
};

const mutations = {
  setServiceProviders: (state, data) => {
    state.serviceProviders = new List({
      items: data.items.map((serviceProvider) => new ServiceProvider(serviceProvider)), totalCount: data.total_count
    });
  },
  setServiceProvider: (state, data) => {
    state.serviceProvider = new ServiceProvider(data);
  },
  setConsultantsList: (state, data) => {
    state.consultantsList = new List({items: data.items, totalCount: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
