export default [
  {
    path: '/sessions',
    name: 'Session',
    redirect: '/login',
    component: () => import('views/main/session/index'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('views/main/session/login')
      }
    ]
  }
];
