import Menu from '../../data/menu';

const state = {
  data: Menu.data,
  searchData: []
};

const getters = {
  menuItems: (state) => state.data,
  availableMenuItems: (state, getters) => {
    return state.data.filter( (item) => {
      return item.access === 'regular' || getters.currentUser.role === 'super';
    });
  },
  searchMenuItems: (state) => state.searchData
};

const mutations = {
  searchTerm: (state, term) => {
    let items = [];
    term = term.toLowerCase();
    state.data.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
      state.searchData = items;
    });
  },
  setNavActive: (state, item) => {
    if (!item.active) {
      state.data.forEach(a => {
        if (state.data.includes(item))
          a.active = false;
        if (!a.children) return false;
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  },
  setActiveRoute: (state, item) => {
    state.data.filter(menuItem => {
      if (menuItem !== item)
        menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  },
  setBadgeValue: (state, payload) => {
    payload.item.badgeValue = payload.value;
  }
};

// actions
const actions = {
  searchTerm: ({commit}, term) => {
    commit('searchTerm', term);
  },
  setNavActive: ({commit}, item) => {
    commit('setNavActive', item);
  },
  setActiveRoute: ({commit}, item) => {
    commit('setActiveRoute', item);
  },
  async setBadgeValue({commit}, item) {
    const response = await this.$app.$http.get('/feedbacks/unread');
    if (response.status === 200) {
      const payload = { item: item, value: response.data.count };
      commit('setBadgeValue', payload);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
