import {List, ListParams} from 'models/list';
import {Quiz} from 'models/quiz';

const state = {
  quizzes: new List(),
  quiz: new Quiz(),
  questions: new List()
};

const getters = {
  quizzes: (state) => state.quizzes,
  quiz: (state) => state.quiz
};

const actions = {
  async getQuizzes({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/quizzes', {params: params});
    if (response.status === 200) {
      commit('setQuizzes', response.data);
    }
  },
  async prepareNewQuiz({commit}) {
    commit('setQuiz', new Quiz());
  },
  async getQuiz({commit}, options) {
    const response = await this.$app.$http.get(`/quizzes/${options.id}`);
    if (response.status === 200) {
      commit('setQuiz', response.data);
    }
  },
  async createQuiz({commit}, data) {
    const response = await this.$app.$http.post('/quizzes', data);
    if (response.status === 200) {
      commit('setQuiz', response.data);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateQuiz({}, data) {
    const response = await this.$app.$http.put(`/quizzes/${data.recordId}`, data.form);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.quiz.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyQuiz({}, id) {
    const response = await this.$app.$http.delete(`/quizzes/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.quiz.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.quiz.entity', 1)}));
    }
  }
};

const mutations = {
  setQuizzes: (state, data) => {
    state.quizzes = new List({
      items: data.items.map((quiz) => new Quiz(quiz)), totalCount: data.total_count
    });
  },
  setQuiz: (state, data) => {
    state.quiz = new Quiz(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
