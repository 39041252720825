/**
 * A class that can return the Admin model
 */
export class Admin {
  /** @param {Object} admin FastJson object. */
  constructor(admin = {}) {
    this.id = admin.id || null;
    if (admin.attributes) {
      this.email = admin.attributes.email || null;
      this.provider = admin.attributes.provider || null;
      this.uid = admin.attributes.uid || null;
      this.allowPasswordChange = admin.attributes.allow_password_change || null;
      this.name = admin.attributes.name || null;
      this.nickname = admin.attributes.nickname || null;
      this.role = admin.attributes.role || null;
    }
  }
}
