import Vue from 'vue';

Vue.mixin({
  methods: {
    getImageURL(path) {
      return `${process.env.VUE_APP_ACTIVE_STORAGE_URL}${path}`;
    },
    displayErrors (errors) {
      this._.forEach(errors, (error) => {
        this.$toasted.error(error, {
          duration: 5000,
          containerClass: 'm-b-0',
          className: 'm-b-0'
        });
      });
    },
    truncate(text, letterCount) {
      if(text && text.length > letterCount) {
        text = text.slice(0, letterCount) + '...';
      }
      return text;
    },
    setPageNumbers(totalCount, itemsPerPage) {
      if (totalCount > itemsPerPage) {
        return Math.ceil(totalCount/itemsPerPage);
      } else {
        return 1;
      }
    },
    getImgUrl(path) {
      if(path){
        return require('assets/images/' + path);
      }
    }
  }
});
