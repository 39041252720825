import { Audio } from 'models/audio';
import { List, ListParams } from 'models/list';

const state = {
  audios: new List(),
  audio: new Audio()
};

const getters = {
  audios: (state) => state.audios,
  audio: (state) => state.audio
};

const actions = {
  async getAudios({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/audios', {params: params});
    if (response.status === 200) {
      commit('setAudios', response.data);
    }
  },

  async getAudio({commit}, options) {
    const response = await this.$app.$http.get(`/audios/${options.id}`);
    if (response.status === 200) {
      commit('setAudio', response.data);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async createAudio({}, data) {
    const response = await this.$app.$http.post('/audios', data);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.audio.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateAudio({}, data) {
    const response = await this.$app.$http.put(`/audios/${data.recordId}`, data.form);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.audio.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyAudio({}, id) {
    const response = await this.$app.$http.delete(`/audios/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.audio.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.audio.entity', 1)}));
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async publishAudio({}, data) {
    const response = await this.$app.$http.put(`/audios/${data.id}/publish`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.published'));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async unpublishAudio({}, data) {
    const response = await this.$app.$http.put(`/audios/${data.id}/unpublish`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.unpublished'));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  }
};

const mutations = {
  setAudios: (state, data) => {
    state.audios = new List({ 
      items: data.items.map((audio) => new Audio(audio)),
      totalCount: data.total_count
    });
  },
  setAudio: (state, data) => {
    state.audio = new Audio(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
