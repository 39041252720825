export default {
  user: {
    entity: 'مستخدم | مستخدمين',
    attributes: {
      email: 'بريد الكتروني',
      password: 'كلمة المرور',
      role: 'وظيفة',
      name: 'الاسم',
      testUser: 'مختبر'
    }
  },
  systemFeedback: {
    entity: 'تقييمات النظام',
    attributes: {
      content: 'محتوى',
      rating: 'تقييم',
      positiveFeedback: 'تقييم إيجابي',
      negativeFeedback: 'تقييم سلبي'
    }
  },
  article: {
    entity: 'مقال | مقالات',
    attributes: {
      title: 'العنوان',
      body: 'المحتوى',
      tags: 'اوسمة',
      image: 'الصورة'
    }
  },
  audio: {
    entity: 'تسجيل | تسجيلات',
    attributes: {
      title: 'العنوان',
      url: 'رابط',
      description: 'توصيف',
      tags: 'اوسمة',
      image: 'الصورة'
    }
  },
  frequentQuestion: {
    entity: 'سؤال متداول | أسئلة متداولة',
    attributes: {
      question: 'السؤال',
      answer: 'الإجابة',
      category: 'الفئة'
    }
  },
  serviceProvider: {
    entity: 'مقدم الخدمة | مقدمي الخدمات',
    attributes: {
      header: 'العنوان',
      address: 'المكان',
      location: 'الموقع الجغرافي',
      description: 'الوصف',
      email: 'البريد الالكتروني',
      phoneNumber: 'رقم الهاتف',
      category: 'الفئة',
      consultant: 'الأخصائي',
      latitude: 'خط العرض',
      longitude: 'خط الطول',
      image: 'الصورة',
      locationImage: 'صورة الموقع'
    }
  },
  feedback: {
    entity: 'تقييم | تقييمات',
    attributes: {
      title: 'عنوان',
      details: 'تفاصيل',
      status: 'الحالة'
    }
  },
  message: {
    entity: 'رسالة | رسائل'
  },
  video: {
    entity: 'فيديو | فيديوهات',
    attributes: {
      title: 'العنوان',
      url: 'الرابط',
      description: 'الوصف',
      tags: 'اوسمة',
      image: 'الصورة'
    }
  },
  admin: {
    entity: 'مشرف | مدراء',
    attributes: {
      role: 'دور',
      email: 'بريد إلكتروني',
      image: 'صورة',
      nickname: 'الاسم المستعار',
      password: 'كلمة السر',
      password_confirmation: 'تأكيد كلمة السر'
    }
  },
  content: {
    entity: 'المحتوى | المحتوى',
    attributes: {
      title: 'العنوان',
      tags: 'اوسمة'
    }
  },
  quiz: {
    entity: 'إختبار | إختبارات',
    attributes: {
      title: 'عنوان',
      instructions: 'تعليمات',
      questions_count: 'عددالأسئلة'
    }
  },
  question: {
    entity: 'سؤال | اسئلة',
    attributes: {
      title: 'عنوان',
      choices: 'اختيارات'
    }
  }
};
