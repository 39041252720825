<template>
    <div class="m-b-20">
      <label v-show="label && !(['switch', 'check'].includes(type))" :class="labelClass">{{customLabel}}</label>
      <template v-if="type === 'text' || type === 'email' || type === 'number' || type === 'url' || type === 'tel'">
        <b-input-group>
          <b-input-group-prepend v-show="iconClass">
            <span class="input-group-text">
              <i :class='iconClass'></i>
            </span>
          </b-input-group-prepend>
          <b-form-input v-model="content" :type="type" :state="state" 
          :disabled="disabled" @keyup="validateField(content)"
          :required="required" :minlength="minLength" :maxlength="maxLength">
          </b-form-input>
        </b-input-group>
      </template>

      <template v-if="type === 'text-area'">
        <b-input-group>
          <b-input-group-prepend v-show="iconClass">
            <span class="input-group-text">
              <i :class='iconClass'></i>
            </span>
          </b-input-group-prepend>
          <b-form-textarea v-model="content" :type="type" :state="state" 
          :disabled="disabled" @keyup="validateField(content)"
          :required="required" :minlength="minLength" :maxlength="maxLength" rows="3"></b-form-textarea>
        </b-input-group>
      </template>
      
      <template v-if="type === 'autocomplete'">
        <vue-bootstrap-typeahead
          :data="searchData"
          v-model="customSearchKey"
          size="lg"
          placeholder="Search tags..."
          @hit="selectedAddress = $event"
        />
      </template>

      <template v-if="type === 'searchableSelect'">
        <div>
          <multiselect 
            v-model="customSearchKey" 
            :taggable="taggable"
            :placeholder="$t('actions.search')"
            open-direction="bottom" 
            :options="searchData" :multiple="allowMultiple" 
            :custom-label="customSelectLabel"
            :searchable="true" :loading="isLoading" 
            :internal-search="false" :clear-on-select="true" 
            :close-on-select="false"
            :selectLabel="selectLabel"
            :max-height="600" :show-no-results="false" 
            :hide-selected="true" @search-change="search"
            @tag="addTag"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag">
                <span>{{ option }}</span>
                <span class="custom__remove" @click="remove(option)"> ❌</span>
              </span>
            </template>
            <template slot="clear" slot-scope="props">
              <div class="multiselect__clear" 
                v-if="customSearchKey && customSearchKey.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
            </template>
          </multiselect>
        </div>
      </template>

      <template v-if="type === 'objectSearchableSelect'">
        <div>
          <multiselect
          v-model="customSearchKey"
          :label="objectLabel"
          :track-by="objectValue"
          :placeholder="$t('actions.search')"
          open-direction="bottom"
          :options="searchData"
          :multiple="allowMultiple"
          :searchable="true"
          :loading="isLoading"
          :internal-search="false"
          :clear-on-select="true"
          :close-on-select="false"
          :limit-text="limitText"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="true"
          @search-change="search"
          >

            <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag">
                <span>{{ option.name }}</span>
                <span class="custom__remove" @click="remove(option)"> ❌</span>
              </span>
            </template>
            <template slot="clear" slot-scope="props">
              <div 
                class="multiselect__clear" 
                v-if="customSearchKey.length" 
                @mousedown.prevent.stop="clearAll(props.search)">
              </div>
            </template>
          </multiselect>
        </div>
      </template>

      <template v-if="type === 'password'">
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text password-icon" @click="togglePassword">
              <i :class="[showPassword ? 'fa fa-eye fa-lg' : 'fa fa-eye-slash fa-lg']"></i>
            </span>
          </b-input-group-prepend>
          <b-form-input v-model="content" :type="showPassword ? 'text' : 'password'" :state="state" 
          :disabled="disabled" @keyup="validateField(content)"
          :required="required" :minlength="minLength" :maxlength="maxLength">
          </b-form-input>
        </b-input-group>
      </template>

      <template v-if="type === 'file'">
        <b-input-group class="custom-file" :state="state" v-if="!origFile">
          <b-form-file v-model="file" class="custom-file-input" :id="`${label}-customFile`" :state="state"
          :required="required" @change="addNameAndValidate" :accept="acceptedFiles" :disabled="disabled" ></b-form-file>
          <label class="custom-file-label" :for="`${label}-customFile`">{{fileName}}</label>
        </b-input-group>
        <div v-else-if="fileType === 'image'" class="text-center">
          <b-card-img :alt="origFile.filename" class="thumb"
                      :src="getImageURL(origFile.url)"></b-card-img>
          <br>
          <a href="javascript:void(0);" @click="deleteFile">{{ $t('actions.delete') }}</a>
        </div>
      </template>

      <template v-if="type === 'avatar'">
            <div class="text-center m-b-20">
              <template>
                <img width="100" height="100" 
                  :src="_.isNil(userPicture) ? '~/assets/images/anonymous.png': userPicture" alt=""/>
              </template>
            </div>
            <b-input-group class="custom-file" :state="state">
              <b-form-file v-model="file" class="custom-file-input" :id="`${label}-customFile`" :state="state"
              :required="required" @change="attachImage"></b-form-file>
              <label class="custom-file-label" :for="`${label}-customFile`">{{fileName}}</label>
            </b-input-group>
      </template>

      <template v-if="type === 'rich-text'">
        <vue-editor v-model="content" 
                    :disabled="disabled" 
                    :required="required" 
                    @blur="validateField(content)"
        ></vue-editor>
      </template>

      <template v-if="type === 'date'">
        <b-input-group :state="state">
          <datepicker input-class="datepicker-here form-control digits" v-model="content" 
                      :format="dateFormat" :disabled-dates="disabledDates" :required="required"
                      @input="validateField"></datepicker>
        </b-input-group>
      </template>
      
      <template v-if="type === 'switch'">
        <div class="row switch-showcase">
            <div class="col-sm-12 media d-flex align-items-center">
              <label :class="[labelClass, 'col-form-label m-r-10']">{{customLabel}}</label>
              <div class="media-body text-right">
                <label class="switch">
                  <input type="checkbox" v-model="content" checked=""><span class="switch-state"></span>
                </label>
              </div>
          </div>
        </div>
      </template>
      
      <template v-if="type === 'check'">
        <div class="row switch-showcase">
            <div class="col-sm-12 media d-flex align-items-center">
              <div class="media-body text-left">
                <label class="d-block" :for="`${label}-chk-ani`" >
                  <input type="checkbox" :id="`${label}-chk-ani`" 
                         v-model="content" @change="validateField">  {{ customLabel }}
                </label>
              </div>
          </div>
        </div>
      </template>
      <template v-if="type === 'select'">
        <multiselect  v-model="content" :options="selectOptions" :multiple="allowMultiple"
                      :label="selectOptionsText" @input="validateField"></multiselect>
      </template>

      <b-form-invalid-feedback :state="state" v-show="invalidFeedback">{{ invalidFeedback }}</b-form-invalid-feedback>
    </div>
</template>
<script>
import { VueEditor } from 'vue2-editor';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

export default {
  name: 'Field',
  components: {
    VueEditor,
    VueBootstrapTypeahead,
    Datepicker,
    Multiselect
  },
  data() {
    return {
      moment,
      showPassword: false,
      state: null,
      invalidFeedback: '',
      editor: null,
      fileName: '',
      file: null,
      isLoading: false,
      disabledDates: {
        to: this.minDate ? moment(this.minDate, 'DD/MM/YYYY').toDate() : '',
        from: this.maxDate ? moment(this.maxDate, 'DD/MM/YYYY').add(1, 'days').toDate() : ''
      },
      userPicture: this.url
    };
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(content) {
        this.$emit('input', content);
      }
    },
    customSearchKey: {
      get() {
        return this.value;
      },
      set(customSearchKey) {
        this.validateField(customSearchKey); 
        this.$emit('input', customSearchKey);
      }
    },
    customLabel() {
      let label = this.label;
      if (label && this.required === true || this.requiredSelect === true) {
        label = `${label} *`;
      }
      return label;
    }
  },
  methods: {
    deleteFile() {
      this.$emit('delete-file');
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    addNameAndValidate(event) {
      this.file = event.target.files[0];
      this.$emit('input', this.file);
      this.fileName = this.file ? this.file.name : '';
      this.validateField(this.file); 
    },
    attachImage(e) {
      let images = e.target.files;
      this.userPicture = images.length > 0 ? URL.createObjectURL(images[0]) : undefined;
    },
    validateField(content) {
      if (this.required && (((typeof content === 'string' && content.trim() === '') || !content || content.length === 0))) {
        this.invalidFeedback = this.$t('messages.error.required', {entity: this.label});
      } else if (this.minLength && this.minLength > this.content.length) {
        this.invalidFeedback = this.$t('messages.error.minCharacter', {
          entity: this.label,
          length: this.minLength
        });
      } else if (this.maxLength && this.maxLength < content.length) {
        this.invalidFeedback = this.$t('messages.error.maxCharacter', {
          entity: this.label,
          length: this.maxLength
        });
      } else if (this.type === 'email' && content !== '' && !this.validEmail(content)) {
        this.invalidFeedback = this.$t('messages.error.emailFormat');
      } else if (this.type === 'tel' && content !== '' && !this.validPhone(content)) {
        this.invalidFeedback = this.$t('messages.error.phoneFormat');
      } else if (this.type === 'url' && content !== '' && !this.validUrl(content)) {
        this.invalidFeedback = this.$t('messages.error.urlFormat');
      }
      else if (this.type === 'file' && content && this.maxSize && content.size > this.maxSize) {
        this.invalidFeedback = this.$t('messages.error.fileSize', {
          entity: this.label,
          size: this.maxSize/1000000
        });
      } else if (this.type === 'date' && this.maxDate && content.setHours(0, 0, 0) > this.moment(this.maxDate, 'DD/MM/YYYY').toDate()) {
        this.invalidFeedback = this.$t('messages.error.lessThanOrEqual', {
          entity: this.label,
          value: this.maxDate
        });
      } else if (this.type === 'date' && this.minDate && content < this.moment(this.minDate, 'DD/MM/YYYY').toDate()) {
        this.invalidFeedback = this.$t('messages.error.greaterThanOrEqual', {
          entity: this.label,
          value: this.minDate
        });
      }
      else {
        this.invalidFeedback = '';
      }

      if(this.invalidFeedback) {
        this.state = false;
      } else {
        this.state = (!content) ? null : true;
      }

      this.$emit('validate', !this.invalidFeedback);
    },
    validEmail(email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPhone(phone) {
      let re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\\./0-9]*$/g;
      return re.test(phone);
    },
    validUrl(url) {
      let re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
      let hasSpaces = /\s/;
      return re.test(url) && !hasSpaces.test(url);
    },
    clearAll() {
      this.searchData = [];
    },
    addTag(newTag) {
      this.searchData.push(newTag);
      if (this.allowMultiple) {
        this.value.push(newTag);
      } else {
        this.content = newTag;
      }
      this.validateField(newTag);
    },
    search(val) {
      this.autocompleteAction(val);
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    fileOptions: {
      type: Object,
      default: () => {
      }
    },
    fileType: String,
    origFile: Object,
    value: [String, Number, Object, Array, File, Date, Boolean],
    valid: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    selectLabel: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: 'col-md-12'
    },
    hidden: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: 'MM/dd/yyyy'
    },
    required: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number,
      default: 0
    },
    maxLength: {
      type: Number
    },
    maxSize: {
      type: Number
    },
    maxDate: {
      type: String
    },
    minDate: {
      type: String
    },
    searchData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selectOptions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    autocompleteAction: {
      type: Function,
      default: (val) => {
      }
    },
    customSelectLabel: {
      type: Function,
      default: (val) => {
        return val;
      }
    },
    allowMultiple: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    selectOptionsText: {
      type: String
    },
    iconClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    taggable : {
      type: Boolean,
      default: false
    },
    url: {
      type: Object
    },
    acceptedFiles : {
      type: String
    },
    objectLabel: {
      type: String
    },
    objectValue: {
      type: String
    },
    limitText: {
      type: Function,
      default: () => {
      }
    }
  },
  created() {
    if (this.type === 'multiselect') {
      this.autocompleteAction('');
    }

    if (this.type === 'avatar' && this.url) {
      this.userPicture = this.getImgUrl(this.url.thumbnailUrl);
    }
  }
};
</script>

<style lang="scss">
  .file-label {
    margin-top: -1rem;
  }
  .v-select.v-input--dense .v-chip {
    margin: 2px !important;
  }
  .full-width {
    max-width: 100% !important;
  }
  .password-icon {
    cursor: pointer;
  }
  .thumb {
    max-height: 100px;
    width: auto !important;
  }

  .custom__remove {
    cursor: pointer;
  }
</style>
