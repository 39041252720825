/**
 * A class that can return the Question model
 */
import { List } from './list';
import { Choice } from './choice';

export class Question {
  /** @param {Object} question FastJson object. */
  constructor(question = {}) {
    this.id = question.id || null;
    this.title = question.attributes ? question.attributes.title : null;
    this.destroy = false;
    this.comment = question.attributes ? question.attributes.comment : null;
    this.image = question.attributes && question.attributes.image ? question.attributes.image : null;
    
    this.choices = question.attributes && question.attributes.choices ? new List({ 
      items: question.attributes.choices.map((choice) => new Choice(choice))
    }) : new List({items: [new Choice()], count: 1});
  }
}
