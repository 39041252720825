import Layout from '../../data/layout';

const state = {
  layout: Layout,
  sidebarType: localStorage.getItem('SidebarType') || 'default',
  canvasClasses: ''
};

// getters
const getters = {
  layout: (state) => state.layout,
  sidebar: (state) =>  state.sidebarType,
  canvasClasses: (state) => state.canvasClasses
};

// mutations
const mutations = {
  setTheme: (state) => {
    const direction = localStorage.getItem('layoutType') || state.layout.settings.layoutType;
    state.layout.settings.layoutType = direction;
    document.body.className = state.layout.color.mixLayout;
    document.body.setAttribute('main-theme-layout', direction);
    document.getElementsByTagName('html')[0].setAttribute('dir', direction);
    const color = localStorage.getItem('color') || state.layout.color.color;
    const layoutVersion = localStorage.getItem('layoutVersion') || state.layout.color.layoutVersion;
    if (color) {
      createStyle(color);
      if (layoutVersion)
        document.body.className = layoutVersion;
    }
  },
  setLayoutType: (state, direction) => {
    document.body.setAttribute('main-theme-layout', direction);
    localStorage.setItem('layoutType', direction);
    state.layout.settings.layoutType = direction;
    document.getElementsByTagName('html')[0].setAttribute('dir', direction);
  },
  setLayout: (state, payload) => {
    document.body.className = payload;
  },
  setColorLightScheme: (state, payload) => {
    setColor(state, payload);
    state.layout.color.layoutVersion = 'light';
    localStorage.setItem('layoutVersion', state.layout.color.layoutVersion);
  },
  setColorDarkScheme: (state, payload) => {
    setColor(state, payload);
    state.layout.color.layoutVersion = 'dark-only';
    localStorage.setItem('layoutVersion', state.layout.color.layoutVersion);
  },
  setCustomizeSidebarType: (state, payload) => {
    localStorage.setItem('SidebarType', payload);
    window.location.reload();
  },
  addCanvasClass: (state) => {
    state.canvasClasses = 'offcanvas-bookmark';
  },
  removeCanvasClass: (state) => {
    state.canvasClasses = '';
  }
};

// actions
const actions = {
  setTheme({commit}) {
    commit('setTheme');
  },
  setLayoutType({commit}, payload) {
    commit('setLayoutType', payload);
  },
  setLayout({commit}, payload) {
    commit('setLayout', payload);
  },
  setColorLightScheme({commit}, payload) {
    commit('setColorLightScheme', payload);
  },
  setColorDarkScheme({commit}, payload) {
    commit('setColorDarkScheme', payload);
  },
  setCustomizeSidebarType({commit}, payload) {
    commit('setCustomizeSidebarType', payload);
  },
  addCanvasClass({commit}) {
    commit('addCanvasClass');
  },
  removeCanvasClass({commit}) {
    commit('removeCanvasClass');
  }
};

// Create style sheet append in head
function createStyle(color) {
  var head = document.head;
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = window.location.origin + '/css/' + color + '.css';
  head.appendChild(link);
}


function setColor(state, color) {
  createStyle(color);
  state.layout.color.color = color;
  if (color === 'color-1') {
    state.layout.color.primaryColor = '#4466f2';
    state.layout.color.secondaryColor = '#1ea6ec';
  } else if (color === 'color-2') {
    state.layout.color.primaryColor = '#0288d1';
    state.layout.color.secondaryColor = '#26c6da';
  } else if (color === 'color-3') {
    state.layout.color.primaryColor = '#8e24aa';
    state.layout.color.secondaryColor = '#ff6e40';
  } else if (color === 'color-4') {
    state.layout.color.primaryColor = '#4c2fbf';
    state.layout.color.secondaryColor = '#2e9de4';
  } else if (color === 'color-5') {
    state.layout.color.primaryColor = '#7c4dff';
    state.layout.color.secondaryColor = '#7b1fa2';
  } else if (color === 'color-6') {
    state.layout.color.primaryColor = '#3949ab';
    state.layout.color.secondaryColor = '#4fc3f7';
  }
  localStorage.setItem('color', state.layout.color.color);
  localStorage.setItem('primaryColor', state.layout.color.primaryColor);
  localStorage.setItem('secondaryColor', state.layout.color.secondaryColor);
  window.location.reload();
}

export default {
  state,
  getters,
  actions,
  mutations
};
