import { Video } from 'models/video';
import { List, ListParams } from 'models/list';

const state = {
  videos: new List(),
  video: new Video()
};

const getters = {
  videos: (state) => state.videos,
  video: (state) => state.video
};

const actions = {
  async getVideos({commit}, options) {
    let params = new ListParams(options);
    const response = await this.$app.$http.get('/videos', {params: params});
    if (response.status === 200) {
      commit('setVideos', response.data);
    }
  },
  async getVideo({commit}, options) {
    const response = await this.$app.$http.get(`/videos/${options.id}`);
    if (response.status === 200) {
      commit('setVideo', response.data);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async createVideo({}, data) {
    const response = await this.$app.$http.post('/videos', data);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.video.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateVideo({}, data) {
    const response = await this.$app.$http.put(`/videos/${data.recordId}`, data.form);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.video.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyVideo({}, id) {
    const response = await this.$app.$http.delete(`/videos/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.video.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.video.entity', 1)}));
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async publishVideo({}, data) {
    const response = await this.$app.$http.put(`/videos/${data.id}/publish`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.published'));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async unpublishVideo({}, data) {
    const response = await this.$app.$http.put(`/videos/${data.id}/unpublish`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.unpublished'));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  }
};

const mutations = {
  setVideos: (state, data) => {
    state.videos = new List({items: data.items.map((video) => new Video(video)), totalCount: data.total_count});
  },
  setVideo: (state, data) => {
    state.video = new Video(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
