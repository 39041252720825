import Field from './Field';
import Form from './Form';
import Pagination from './Paginate';

export default {
  install(Vue) {
    Vue.component('AppField', Field);
    Vue.component('AppForm', Form);
    Vue.component('Pagination', Pagination);
  }
};
