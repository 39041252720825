import * as AR from './ar';
import * as EN from './en';

export default {
  'ar-EG': {
    actions: AR.Actions,
    messages: AR.Messages,
    models: AR.Models,
    views: AR.Views
  },
  'en-US': {
    actions: EN.Actions,
    messages: EN.Messages,
    models: EN.Models,
    views: EN.Views
  }
};
