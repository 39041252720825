import _ from 'lodash';
/**
 * A class that can return the List model
 */
export class List {
  /** @param {Object} list object with 2 attributes: items and count (represents the total count) */
  constructor(list = {}) {
    this.items = list.items || [];
    this.totalCount = list.totalCount || 0;
  }
}

/**
 * A class that can return the ListParams model
 */
export class ListParams {
  /** @param {Object} options represents the datatable pagination options */
  constructor(options = {}) {
    this.sort_by = options.sortBy && options.sortBy[0] ? _.snakeCase(options.sortBy[0]) : null;
    this.sort_dir = options.sortDesc && options.sortDesc[0] ? 'desc' : 'asc';
    this.page = options.itemsPerPage !== -1 ? options.page : null;
    this.limit = options.itemsPerPage !== -1 ? options.itemsPerPage : null;
    this.fields = options.fields ? options.fields.map((field) => _.snakeCase(field)) : [];
    this.tags = null;
    if (options.tags) {
      let tagsArray = [];
      Object.keys(options.tags).forEach(function (key) { 
        if (options.tags[key]) {
          tagsArray.push(key);
        }
      });
      this.tags = tagsArray;
    }
    if (options.filterBy) {
      this.filters = {};
      Object.keys(options.filterBy).map((field) => this.filters[_.snakeCase(field)] = options.filterBy[field]);
    }
  }
}
