<template>
  <nav aria-label="Page navigation example m-t-10">
    <paginate
      v-model="options.page"
      :page-count="pages"
      :click-handler="paginate"
      :prev-text="$t('views.constants.prev')"
      :next-text="$t('views.constants.next')"
      :page-range="3"
      container-class="pagination pagination-primary"
      page-class="page-item"
      page-link-class="page-link"
      next-class="page-item"
      next-link-class="page-link"
      prev-class="page-item"
      prev-link-class="page-link">
    </paginate>
  </nav>
</template>

<script>
import Paginate from 'vuejs-paginate';
export default {
  props: {
    options: {
      type: Object,
      required: true
    },
    pages: {
      type: Number,
      required: true
    }
  },
  components: {
    Paginate
  },
  methods: {
    paginate(pageNum) {
      this.$emit('paginate', pageNum);
    }
  }
};
</script>
