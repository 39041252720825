<template>
  <div id="app">
    <div class="loader-wrapper"  :class="{'loderhide': !show}">
      <div class="loader bg-white">
        <div class="whirly-loader"></div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      show: true
    };
  },
  mounted() {
    this.timeOut();
    localStorage.locale = localStorage.locale ? localStorage.locale : 'en';
  },
  methods:{
    timeOut() {
      setTimeout(() => {this.show = false;}, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
