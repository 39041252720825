/**
 * A class that can return the User model
 */
export class User {
  /** @param {Object} user FastJson object. */
  constructor(user = {}) {
    this.id = user.id || null;
    if (user.attributes) {
      this.email = user.attributes.email || null;
      this.provider = user.attributes.provider || null;
      this.uid = user.attributes.uid || null;
      this.allowPasswordChange = user.attributes.allow_password_change || null;
      this.name = user.attributes.name || null;
      this.role = user.attributes.role || null;
      this.testUser = user.attributes.test_user || null;
    }
  }
}
