export default {
  error: {
    // Server responses
    internalServerError: 'حدث خطأ ما!',
    forbidden: 'ليس لديك الصلاحية للدخول على هذا المحتوى',
    authentication: 'البريد الإلكتروني أو كلمة المرور غير صحيحة!',
    created: 'حدث خطأ أثناء الإنشاء!',
    updated: 'حدث خطأ أثناء التحديث!',
    deleted: 'حدث خطأ أثناء الحذف!',
    notFound: '{entity} غير موجود!',

    // Input Fields errors:
    required: '{entity} مطلوب',
    emailFormat: 'بريد الكتروني غير صحيح',
    phoneFormat: 'رقم هاتف غير صحيح',
    noContent: 'لا يوجد محتوى',
    urlFormat: 'رابط غير صحيح',
    maxCharacter: 'يجب ان يكون عدد الحروف أقل من {length}',
    minCharacter: 'يجب ان يكون عدد الحروف أكثر من {length}',
    lessThan: '{entity} يجب ان يكون أقل من {value}',
    lessThanOrEqual: '{entity} يجب ان يكون أقل من او يساوي {value}',
    greaterThan: '{entity} يجب ان يكون أكبر من {value}',
    greaterThanOrEqual: '{entity} يجب ان يكون أكبر من أو يساوي {value}',
    unmatchPassword: 'كلمات المرور غير متطابقة',
    fileSize: '{entity} يجب ان يكون أقل من {size} ميجا بايت',

    //Page Errors
    backToHome: 'العودة للصفحة الرئيسية',
    pageNotAvailable: '.الصفحة التي تحاول الوصول إليها غير متاحة',
    user: {
      alreadyExits: 'هذا البريد الالكتروني مستخدم',
      invitaion: 'لم يتم إرسال الدعوة'
    },
    quiz: {
      questionTitle: 'هذا سؤال ليس لديه على عنوان',
      correctChoices: 'هذا سؤال لا يحتوي على إختيار صحيح',
      choiceTitle: ' إختيار #{index} ليس لديه عنوان',
      questionsCount: 'هذا إختبار لا يحتوي على اسئلة',
      choicesCount: 'هذا السؤال لا يحتوي على إختيارات'
    }
  },
  success: {
    invitation: 'لقد تم دعوة المستخدم بنجاح',
    authentication: 'أهلا بك!',
    created: ' {entity} تم انشاؤه بنجاح.',
    updated: ' {entity} تم تحديثه بنجاح.',
    deleted: ' {entity} تم مسحه بنجاح.',
    resetPassword: 'برجاء التحقق من بريدك الالكتروني لاتباع تعليمات انشاء كلمة سر جديدة.'
  }
};
