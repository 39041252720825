/**
 * A class that can return the Video model
 */
export class Video {
  /** @param {Object} video represents the API file object */
  constructor(video = {}) {
    this.id = video.id || null;
    if (video.attributes) {
      this.title = video.attributes.title || null;
      this.url = video.attributes.url || null;
      this.description = video.attributes.description || null;
      this.truncated_description = video.attributes.truncated_description || null;
      this.author_id = video.attributes.author_id || null;
      this.truncated_description = video.attributes.truncated_description || null;
      this.createdAt = video.attributes.created_at || null;
      this.searchableItemAttributes = video.attributes.tags || null;
      this.viewsCount = video.attributes.views_count || null;
      this.tags = video.attributes.tags || null;
      this.image = video.attributes.image || null;
      this.published = video.attributes.published || null;
    }
  }
}
