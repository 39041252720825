import Vue from 'vue';
import Router from 'vue-router';
import _ from 'lodash';
import errorsRoutes from './errors';
import mainRoutes from './main';
import sessionRoutes from './session';
import store from '../store';

Vue.use(Router);

const routes = [
  ...errorsRoutes,
  ...mainRoutes,
  ...sessionRoutes
];

const $store = store;
const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if(to.meta.title) {
    document.title = to.meta.title;
  }

  if (_.isEmpty($store.getters.accessToken) && _.isNil($store.getters.currentUser.id)) {
    if (to.name === 'Login' || to.name === 'ResetPassword') {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
