/**
 * A class that can return the FrequentQuestion model
 */
export class FrequentQuestion {
  /** @param {Object} frequentQuestion represents the API file object */
  constructor(frequentQuestion = {}) {
    this.id = frequentQuestion.id || null;
    if (frequentQuestion.attributes) {
      this.question = frequentQuestion.attributes.question || null;
      this.answer = frequentQuestion.attributes.answer || null;
      this.authorId = frequentQuestion.attributes.author_id || null;
      this.tagList = frequentQuestion.attributes.tags || null;
    }
  }
}

