/**
 * A class that can return the Audio model
 */
export class Audio {
  /** @param {Object} audio represents the API file object */
  constructor(audio = {}) {
    this.id = audio.id || null;
    if (audio.attributes) {
      this.title = audio.attributes.title || null;
      this.description = audio.attributes.description || null;
      this.truncated_description = audio.attributes.truncated_description || null;
      this.url = audio.attributes.url || null;
      this.tags = audio.attributes.tags || null;
      this.createdAt = audio.attributes.created_at || null;
      this.authorId = audio.attributes.author_id || null;
      this.searchableItemAttributes = audio.attributes.tags || null;
      this.viewsCount = audio.attributes.views_count || null;
      this.image = audio.attributes.image || null;
      this.published = audio.attributes.published || null;
    }
  }
}
