export default {
  constants: {
    signIn: 'LOGIN',
    signInMessage: 'Enter your Username and Password',
    next: 'Next',
    prev: 'Prev',
    searchEntity: 'Find {entity}',
    question: 'Question',
    article: 'Article',
    frequentQuestion: 'Frequent Question',
    filter: 'Filter',
    edit: 'Edit',
    new: 'New',
    totalUnread: 'Total unread:',
    send: 'Send',
    search: 'Search',
    typeMessage: 'Type a message...',
    editProfile: 'Edit Profile',
    logout: 'Logout',
    viewsCount: 'Views count',
    reviews: 'Reviews'
  },
  breadcrumbs: {
    new: 'New {entity}',
    edit: 'Edit {entity}'
  },
  placeholders: {
    quiz: {
      questionTitle: 'Question Title',
      questionComment: 'Question Comment',
      option: 'Option',
      instructions: 'Quiz Instructions'
    },
    multiple_choices: 'and {count} other Consultants'
  }
};
