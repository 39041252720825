import { List, ListParams } from 'models/list';

const state = {
  tagsList: new List()
};

const getters = {
  tagsList: (state) => state.tagsList
};

const actions = {
  async searchTags({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/list_tags?model=' + params['filters']['model'] + '&key=' + params['filters']['key']);
    if (response.status === 200) {
      commit('setTagsList', response.data);
    }
  }
};

const mutations = {
  setTagsList: (state, data) => {
    state.tagsList = new List({items: data.items, totalCount: data.count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
