import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import 'es6-promise/auto';
import axios from '../plugins/axios';
import layout from './modules/layout';
import menu from './modules/menu';
import session from './modules/session';
import admin from './modules/admin';
import systemFeedback from './modules/system_feedback';
import articles from './modules/articles';
import audios from './modules/audios';
import serviceProviders from './modules/service-providers';
import frequentQuestions from './modules/frequent-questions';
import users from './modules/users';
import search from './modules/search';
import messages from './modules/messages';
import videos from './modules/videos';
import quizzes from './modules/quizzes';
import feedbacks from './modules/feedbacks';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    layout,
    menu,
    session,
    admin,
    systemFeedback,
    search,
    articles,
    audios,
    frequentQuestions,
    serviceProviders,
    users,
    messages,
    videos,
    quizzes,
    feedbacks
  }
});
