/**
 * A class that can return the Message model
 */
import { User } from 'models/user';
import { Reply } from 'models/reply';

export class Message {
  /** @param {Object} message represents the API file object */
  constructor(message = {}) {
    this.id = message.id || null;
    if (message.attributes) {
      this.subject = message.attributes.subject || null;
      this.hasUnread = message.attributes.has_unread || null;
      this.lastSeenAt = message.attributes.last_seen_at || null;
      this.lastSeenBy = message.attributes.last_seen_by || null;
      this.unreadCount = message.attributes.unread_admin_count || null;
      this.sender = message.attributes.sender ? new User(message.attributes.sender) : {};
      this.lastReply = message.attributes.last_reply ? new Reply(message.attributes.last_reply) : {};
    }
  }
}
