export const languages = {
  ar: {
    name: 'ع',
    locale: 'ar',
    i18nLocale: 'ar-EG',
    flag: 'eg'
  },
  en: {
    name: 'EN',
    locale: 'en',
    i18nLocale: 'en-US',
    flag: 'is'
  }
};

export const adminRoles = {
  SUPER: 'super'
};

export const feedbackStatus = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected'
};
