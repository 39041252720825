import { List, ListParams } from 'models/list';
import { Admin } from 'models/admin';

const state = {
  admins: new List(),
  admin: new Admin()
};

const getters = {
  admins: (state) => state.admins,
  admin: (state) => state.admin
};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async inviteAdmins({}, user) {
    const response = await this.$app.$http.post('/invite_admin', user);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.invitation')); 
      this.$app.$router.push({ name: 'AdminList' });
    } else if (response.status === 208) {
      this.$app.$toasted.error(this.$app.$t('messages.error.user.alreadyExits'));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.user.invitation'));
    }
  }, 
  async getAdmins({commit}, options) {
    let params = new ListParams(options);

    const response = await this.$app.$http.get('/admins', {params: params});
    if (response.status === 200) {
      commit('setAdmins', response.data);
    } else {
      this.$app.displayErrors(response.errors);
    }
  },
  async getAdmin({commit}, option) {
    const response = await this.$app.$http.get(`/admins/${option.id}`);
    if (response.status === 200) {
      commit('setAdmin', response.data);
    } else {
      this.$app.displayErrors(response.errors);
    }
  },
  async updateAdmin({commit, rootState}, data) {
    const response = await this.$app.$http.put(`/admins/${data.recordId}`, data.form);
    if (response.status === 200) {
      if (`${data.id}}` === rootState.session.uid) {
        commit('setCurrentUserData', new Admin(response.data.data));
      }
      commit('setAdmin', response.data);
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.admin.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyAdmin({}, id) {
    const response = await this.$app.$http.delete(`/admins/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.admin.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.admin.entity', 1)}));
    }
  }
};

const mutations = {
  setAdmins: (state, data) => {
    state.admins = new List({items: data.items.map((admin) => new Admin(admin)), totalCount: data.total_count});
  },
  setAdmin: (state, data) => {
    state.admin = new Admin(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
