import { SystemFeedback } from 'models/system_feedback';
import { List, ListParams } from 'models/list';

const state = {
  systemFeedbacks: new List()
};

const getters = {
  systemFeedbacks: (state) => state.systemFeedbacks
};

const actions = {
  async getSystemFeedbacks({commit}, options) {
    let params = new ListParams(options);
    const response = await this.$app.$http.get('/system_feedbacks', {params: params});
    if(response.status === 200) {
      commit('setSystemFeedbacks', response.data);
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async markAsRead({}, id) {
    const response = await this.$app.$http.get(`/system_feedbacks/${id}/mark_feedback_as_read`);
    if(response.status !== 200) {
      this.$app.$toasted.error(this.$app.$t('messages.error.internalServerError'));
    }
  }
};

const mutations = {
  setSystemFeedbacks: (state, data) => {
    state.systemFeedbacks = new List({
      items: data.items.map((systemFeedback) =>
        new SystemFeedback(systemFeedback)), totalCount: data.total_count
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
