<template>
   <b-form ref="form" :novalidate="true" @submit.prevent="onSubmit" class="row">
      <div v-for="field in fields" :key="field.key" v-show="!field.hidden" :class="field.classes">
        <app-field v-bind="field"
                  @input="bindValue($event, field)"
                  @validate="bindValid($event, field)"
                  @delete-file="deleteFile(field)"/>
      </div>

      <div class="col-12">
        <div :class="footerClass">
          <button type="submit" :disabled="!valid || submitButton.disabled"
                  :class="['btn btn-primary btn-block', submitButton.classes]" v-if="showSubmitButton">
            <span>{{ submitButton.label }}</span>
          </button>
        </div>
      </div>
   </b-form>
</template>

<script>
export default ({
  name: 'Form',
  data() {
    return {
      resteted: false
    };
  },
  computed: {
    valid() {
      return !this._.find(this.fields, (field) => !field.valid);
    }
  },
  props: {
    fields: {
      type: [Object, Array],
      required: true
    },
    footerClass: {
      type: String,
      required: false,
      default: 'text-right'
    },
    submitButton: {
      type: Object,
      required: false,
      default() {
        return {
          label: this.$t('actions.submit'),
          block: false,
          disabled: false
        };
      }
    },
    showSubmitButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    bindValue(value, field) {
      this.$set(field, 'value', value);
      this.$forceUpdate();
    },
    bindValid(value, field) {
      this.$set(field, 'valid', value);
      this.$forceUpdate();
    },
    deleteFile(field) {
      this.$set(field, 'origFile', null);
      this.$set(field, 'value', null);
      this.$forceUpdate();
    },
    onSubmit() {
      if (this.valid) {
        const data = {};
        this._.forEach(this.fields, (field) => {
          if (field.type === 'check' && field.key !== 'testUser' && field.key !== 'authorId') {
            data[field.key] = field.value;
          } else {
            data[this._.snakeCase(field.key)] = field.value;
          }
        });
        this.$emit('submitted', data);
      }
    },
    resetForm() {
      this._.forEach(this.fields, (field) => {
        this.fields[field.key].value = this.fields[field.key].allowMultiple ? [] : '';
        this.fields[field.key].valid = !this.fields[field.key].required;
      });
    }
  }
});
</script>

