import { FrequentQuestion } from 'models/frequent_question';
import { List, ListParams } from 'models/list';

const state = {
  frequentQuestions: new List(),
  frequentQuestion: new FrequentQuestion()
};

const getters = {
  frequentQuestions: (state) => state.frequentQuestions,
  frequentQuestion: (state) => state.frequentQuestion
};

const actions = {
  async getFrequentQuestions({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/frequent_questions', {params: params});
    if (response.status === 200) {
      commit('setFrequentQuestions', response.data);
    }
  },

  async getFrequentQuestion({commit}, options) {
    const response = await this.$app.$http.get(`/frequent_questions/${options.id}`);
    if (response.status === 200) {
      commit('setFrequentQuestion', response.data);
    }
  },
  async createFrequentQuestion({commit}, data) {
    const response = await this.$app.$http.post('/frequent_questions', data);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.frequentQuestion.entity', 1)
      }));
      commit('setFrequentQuestion', response.data);
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateFrequentQuestion({}, data) {
    const response = await this.$app.$http.put(`/frequent_questions/${data.recordId}`, data.form);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.frequentQuestion.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyFrequentQuestion({}, id) {
    const response = await this.$app.$http.delete(`/frequent_questions/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.frequentQuestion.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.frequentQuestion.entity', 1)}));
    }
  }
};

const mutations = {
  setFrequentQuestions: (state, data) => {
    state.frequentQuestions = new List({
      items: data.items.map((frequentQuestion) => new FrequentQuestion(frequentQuestion)), totalCount: data.total_count
    });
  },
  setFrequentQuestion: (state, data) => {
    state.frequentQuestion = new FrequentQuestion(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
