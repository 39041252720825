import { User } from 'models/user';
import { List, ListParams } from 'models/list';

const state = {
  users: new List(),
  user: new User()
};

const getters = {
  users: (state) => state.users,
  user: (state) => state.user
};

const actions = {
  async getUsers({commit}, options) {
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/users', {params: params});
    if (response.status === 200) {
      commit('setUsers', response.data);
    } else {
      this.$app.displayErrors(response.errors);
    }
  },
  async getUser({commit}, options) {
    const response = await this.$app.$http.get(`/users/${options.id}`);
    if (response.status === 200) {
      commit('setUser', response.data);
    } else {
      this.$app.displayErrors(response.errors);
    }
  },
  async updateUser({commit}, data) {
    const response = await this.$app.$http.put(`/users/${data.recordId}`, data.form);
    if (response.status === 200) {
      commit('setUser', response.data);
      this.$app.$toasted.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.user.entity', 1)
      }));
    } else {
      this.$app.displayErrors(response.errors);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyUser({}, id) {
    const response = await this.$app.$http.delete(`/users/${id}`);
    if (response.status === 200) {
      this.$app.$toasted.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    } else {
      this.$app.$toasted.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    }
  }
};

const mutations = {
  setUsers: (state, data) => {
    state.users = new List({
      items: data.items.map((user) => new User(user)), totalCount: data.total_count
    });
  },
  setUser: (state, data) => {
    state.user = new User(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
