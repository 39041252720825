export default {
  submit: 'إرسال',
  login: 'تسجيل الدخول',
  setPassword: 'انشاء كلمة المرور',
  resetPassword: 'اعادة انشاء كلمة المرور',
  register: 'انشاء حساب',
  new: 'جديد',
  create: 'انشاء {entity}',
  find: 'بحث في {entity}',
  search: 'بحث',
  noOptions: 'لا نتائج',
  save: 'حفظ',
  confirm: 'تأكيد',
  cancel: 'الغاء',
  update: 'تحديث',
  invite: 'إرسال دعوة',
  list: 'قائمة',
  filter: 'بحث',
  show: 'عرض',
  read: 'تمت قرأته',
  findAudios: 'بحث الصوتيات',
  createdByMe: 'من انشائي',
  deleteConfirmation: 'هل انت متأكد انك تريد مسح هذا السجل؟',
  delete: 'مسح',
  addQuestion: 'إضافة سؤال جديد',
  addChoice: 'إضافة سؤال إختيار',
  accept: 'قبول',
  reject: 'رفض'
};
